<template>
  <!-- hero section -->
  <main class="hero">
    <div class="wrapper">
      <div class>
        <h1>Select Account Type</h1>
      </div>

      <div class="experience-grid">
        <a href="https://app.hayche.net">
          <div class="experience-item">
            <div>
              <img src="@/assets/icons/account-gateway/professional.svg" alt="Proessioanl" />
              <p>Professional</p>
            </div>
          </div>
        </a>

        <a href="https://business.hayche.net">
          <div class="experience-item">
            <div>
              <img src="@/assets/icons/account-gateway/business.svg" alt="Business" />
              <p>Business</p>
            </div>
          </div>
        </a>

        <a href="https://review.hayche.net">
          <div class="experience-item">
            <div>
              <img src="@/assets/icons/account-gateway/reviewer.svg" alt="Reviewer" />
              <p>Reviewer</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </main>
</template>

<style scoped>
/* start of hero */
.hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("https://res.cloudinary.com/hayche-network/image/upload/v1646928338/website/images/contact/hero_voivk8.png");
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .hero {
    height: 100%;
  }
}

.wrapper {
  display: grid;
  place-items: center;
  height: 600px;
  place-items: 0 auto;
  padding-top: 2em;
  padding-bottom: 2em;
}

.experience-grid {
  width: 50%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 40px;
}

@media (max-width: 768px) {
  .experience-grid {
    width: 60%;
    margin-top: 1em;
    margin-bottom: 1em;
    gap: 10px;
  }
}

.experience-item {
  width: 150px;
  height: 150px;
  background: rgba(0, 0, 0, 0.319);
  border: 1px solid rgba(255, 255, 255, 0.364);
  box-sizing: border-box;
  border-radius: 30px;
  padding-top: 40px;
  cursor: pointer;
  transition: background-color 2s, transform 0.5s;
}

.experience-item:hover {
  transition: background-color 2s, transform 0.5s;

  transform: rotate(-1deg) scale(1.07);
}

.experience-item > div {
  display: grid;
  display: grid;
  place-items: center;
}

.experience-item img {
  width: 35px;
  height: 35px;
  margin-bottom: 1em;
}

.experience-item p {
  font-family: "Optima";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.7);
}

h1 {
  font-family: "Optima";
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  color: #ffffff;
}

/* end of hero */
</style>
